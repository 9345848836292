<template>
  <div class="culture">
    <beacon-banner
      :bannerSrc="banner_5"
      title="企业文化是碧耕公司赖以生存的根本"
      subTitle="corporate  culture"
    ></beacon-banner>
    <div class="title">企业文化</div>
    <div class="content">
      <ul class="culture_list">
        <li
          class="culture_item"
          v-for="(culture, index) in cultureList"
          :key="index"
        >
          <img class="culture_img" :src="culture.img" alt="" />
          <div class="culture_curtain">
            <div class="culture_title">{{ culture.title }}</div>
            <div class="desc">
              <div
                class="desc_item"
                v-for="(descItem, index) in culture.desc"
                :key="index"
              >
                {{ descItem }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { banner_5 } from "@/utils/images";
export default {
  data() {
    return {
      banner_5,
      cultureList: [
        {
          img: require("@/utils/images")[`culture_1`],
          title: "使命",
          desc: ["健康发展，促进环保。", "事业精进，践行文明。"],
        },
        {
          img: require("@/utils/images")[`culture_2`],
          title: "愿景",
          desc: [
            "培养一个成功企业“家”应有心态，",
            "创造一个积极净化的经济趋势。",
          ],
        },
        {
          img: require("@/utils/images")[`culture_3`],
          title: "理念",
          desc: [
            "个体的成长在团队，团队的成长在文化，",
            "文化的进化在使命，使命的进化在文明。",
          ],
        },
        {
          img: require("@/utils/images")[`culture_4`],
          title: "价值观",
          desc: [
            "个体的进化在团队历练，团队的凝聚在文化沉淀，",
            "文化的融合在使命推动，使命的深远在文明传承。",
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.culture_list {
  overflow: hidden;
  .culture_item {
    margin-left: 1.8%;
    margin-top: 1.8%;
    width: 49.1%;
    background-color: #f5f8fa;
    float: left;
    position: relative;
    overflow: hidden;
    &:hover {
      .culture_curtain {
        transform: translateY(0);
        background: rgba(76, 176, 52, 0.6);
      }
      .desc {
        opacity: 1;
      }
    }
    .culture_curtain {
      padding: 6.99% 0 4.17% 6.99%;
      position: absolute;
      bottom: 0;
      background-color: transparent;
      width: 100%;
      transform: translateY(39.9%);
      transition: 0.3s;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-left: 0 !important;
      &:not(:first-child) {
        margin-top: 5.8% !important;
      }
    }
    .culture_img {
      display: block;
      width: 100%;
    }
    .culture_title {
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;

      font-size: 2.5rem;
    }
    .desc {
      margin-top: 3.57%;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      opacity: 0;
      transition: 0.3s;
      font-size: 1rem;
      line-height: 1.67rem;
    }
    &:nth-child(2n + 1) {
      margin-left: 0;
    }
    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .culture_list {
    .culture_item {
      &:not(:first-child) {
        margin-top: 20vpx !important;
      }
      .culture_title {
        font-size: 24vpx;
      }
      .desc {
        font-size: 12vpx;
        line-height: 20vpx;
      }
    }
  }
}
</style>